import React from "react";

const Header = () => {
    return (
        <header>
            헤더 영역 입니다.
            <hr />
        </header>
    )
};

export default Header;
