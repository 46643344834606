import React from "react";

function App() {
  return (
    <div>
      콘텐츠 영역 입니다.<br /><br />
      <h1>
        웹 페이지 열심히 만들고 있습니다.<br />
      </h1>
      <span>
        - 미리칸 -
      </span>
    </div>
  );
}

export default App;
